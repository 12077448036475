<template>
  <div class="content">
    <div class="header">
      <img src="./assets/logo-1.png" class="logo mt-5" alt="">
    </div>
    <div class="bg-1 flex-col fer-v mt-10">
      <div class="content-view flex-row fer-v">
        <img src="./assets/ic_header.png" alt="">
      </div>
    </div>
    <div class="flex-col fer-v bg-white pd-v-20">
      <div class="content-view flex-col fer-v mt-20">
        <span class="main-title">“辰星策”手机版</span>
        <span class="mt-20 sub-title font-26">全国首款八字+占星全息命理解析系统</span>
        <img src="./assets/ic_content_1.png" class="mt-20" alt="">
      </div>
    </div>
    <div class="flex-col fer-v bg-white pd-v-20 bg-1">
      <div class="content-view flex-col fer-v mt-20">
        <span class="main-title">“辰星策”专业版</span>
        <span class="mt-20 sub-title font-26">致力于打造最专业实用的排盘工具</span>
        <img src="./assets/ic_content_2.png" class="mt-20" alt="">
      </div>
    </div>
    <div class="flex-col fer-v bg-white pd-v-20">
      <div class="content-view flex-col fer-v mt-20">
        <span class="main-title">关于辰星策</span>
        <span class="mt-20 sub-title font-26">秉持“器以载道”之宗旨，古为今用、中西合璧，与多位专业学者深度合力，专业严谨打造“古代文化+互联网/人工智能”的服务平台。研发出品“辰星策”智能测评系统，大幅度弥补超越了过往同类产品的诸多不足，并创新开发出更精准更多维领域的拓展应用，令中西方古代星相命理学的高级智慧得以更多的普世发扬。</span>
      </div>
    </div>
    <div class="footer">
      <div class="flex-col">
        <span class="main-color">COPYRIGHT © 2023-2024 TENCENT. ALL RIGHTS RESERVED</span>
        <span class="main-color mt-5">粤ICP备17111418号-1</span>
        <span class="main-color mt-5">深圳市点犀信息科技有限公司版权所有</span>
      </div>
      <div class="flex-col ml-20 main-color">
        <span class="font-20">联系我们</span>
        <span class="mt-5">电话：130 3337 9998</span>
        <span class="mt-5">E-mail：13033379998@wo.cn</span>
      </div>
      <div class="flex-col ml-20 main-color">
        <span class="font-20">H5地址</span>
        <img src="./assets/h5.png" width="80" height="80" class="mt-5" alt="">
      </div>
<!--      <div class="flex-col ml-20 main-color">-->
<!--        <span class="font-20">APP</span>-->
<!--        <img src="./assets/h5.png" width="80" height="80" class="mt-5" alt="">-->
<!--      </div>-->
    </div>
  </div>
</template>

<script setup>

import {onMounted} from "vue";

onMounted(() => {
  // fetch('https://admin.chenxingce.com/api/cxc/app/version/getLatestVersion?platform=android', {
  //   method: 'GET',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     token: ""
  //   }
  // })
  //     .then(response => response.json())
  //     .then(data => console.log(data))
  //     .catch(error => console.error('Error:', error));
})
</script>

<style>
body {
  margin: 0;
}
</style>
<style lang="scss" scoped>
.flex-col {
  display: flex;
  flex-direction: column;
}
.ml-20 {
  margin-left: 20px;
}

.bg-1 {
  background: linear-gradient(0deg, rgba(238, 57, 122, 0.1), rgba(122, 83, 253, 0.1));
  width: 100%;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.main-title {
  font-size: 46px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  line-height: 1.5;
  color: #333333;
}

.sub-title {
  color: #666666;
  line-height: 1.5;
}
.bg-white {
  background-color: #FFFFFF;
}
.pd-v-20 {
  padding: 20px 0;
}

.flex-1 {
  flex: 1;
}

.fer-v {
  align-items: center;
}

.font-32 {
  font-size: 24px;
}
.font-26 {
  font-size: 26px;
}
.font-20 {
  font-size: 20px;
}
.font-weight-bold {
  font-weight: 600;
}

.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  .content-view {
    width: 1200px;
  }

  .header {
    width: 1200px;
    display: flex;
    flex-direction: row;

    .logo {
      width: 100px;
      height: 50px;
    }
  }
  .footer {
    background-color: #2a2a2a;
    width: 100%;
    display: flex;
    flex-direction: row;
    //align-items: center;
    justify-content: center;
    padding: 20px 0;
    .main-color {
      color: #8d8d8d;
      font-size: 16px;
      line-height: 1.5;
    }
  }
}
</style>
